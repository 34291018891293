import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as indextTkPG1jUYQMeta } from "/workspace/pages/login/index.vue?macro=true";
import { default as index46RszXId8PMeta } from "/workspace/pages/logout/index.vue?macro=true";
import { default as indexOBX3ADFOvXMeta } from "/workspace/pages/notifications/index.vue?macro=true";
import { default as indexINSHH3i4rpMeta } from "/workspace/pages/statistics/index.vue?macro=true";
import { default as indexZuTMMwsAT1Meta } from "/workspace/pages/tasks/[id]/index.vue?macro=true";
import { default as indexb0zMWAZxrtMeta } from "/workspace/pages/tasks/index.vue?macro=true";
import { default as indexgD7ow63TYOMeta } from "/workspace/pages/tasks/new/index.vue?macro=true";
import { default as indexIHAscP6Q13Meta } from "/workspace/pages/users/[id]/index.vue?macro=true";
import { default as indexFDpy1ms2QGMeta } from "/workspace/pages/users/index.vue?macro=true";
import { default as indexl3kHCARXZ4Meta } from "/workspace/pages/users/new/index.vue?macro=true";
export default [
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indextTkPG1jUYQMeta?.name ?? "login",
    path: indextTkPG1jUYQMeta?.path ?? "/login",
    meta: indextTkPG1jUYQMeta || {},
    alias: indextTkPG1jUYQMeta?.alias || [],
    redirect: indextTkPG1jUYQMeta?.redirect,
    component: () => import("/workspace/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index46RszXId8PMeta?.name ?? "logout",
    path: index46RszXId8PMeta?.path ?? "/logout",
    meta: index46RszXId8PMeta || {},
    alias: index46RszXId8PMeta?.alias || [],
    redirect: index46RszXId8PMeta?.redirect,
    component: () => import("/workspace/pages/logout/index.vue").then(m => m.default || m)
  },
  {
    name: indexOBX3ADFOvXMeta?.name ?? "notifications",
    path: indexOBX3ADFOvXMeta?.path ?? "/notifications",
    meta: indexOBX3ADFOvXMeta || {},
    alias: indexOBX3ADFOvXMeta?.alias || [],
    redirect: indexOBX3ADFOvXMeta?.redirect,
    component: () => import("/workspace/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexINSHH3i4rpMeta?.name ?? "statistics",
    path: indexINSHH3i4rpMeta?.path ?? "/statistics",
    meta: indexINSHH3i4rpMeta || {},
    alias: indexINSHH3i4rpMeta?.alias || [],
    redirect: indexINSHH3i4rpMeta?.redirect,
    component: () => import("/workspace/pages/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: indexZuTMMwsAT1Meta?.name ?? "tasks-id",
    path: indexZuTMMwsAT1Meta?.path ?? "/tasks/:id()",
    meta: indexZuTMMwsAT1Meta || {},
    alias: indexZuTMMwsAT1Meta?.alias || [],
    redirect: indexZuTMMwsAT1Meta?.redirect,
    component: () => import("/workspace/pages/tasks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexb0zMWAZxrtMeta?.name ?? "tasks",
    path: indexb0zMWAZxrtMeta?.path ?? "/tasks",
    meta: indexb0zMWAZxrtMeta || {},
    alias: indexb0zMWAZxrtMeta?.alias || [],
    redirect: indexb0zMWAZxrtMeta?.redirect,
    component: () => import("/workspace/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: indexgD7ow63TYOMeta?.name ?? "tasks-new",
    path: indexgD7ow63TYOMeta?.path ?? "/tasks/new",
    meta: indexgD7ow63TYOMeta || {},
    alias: indexgD7ow63TYOMeta?.alias || [],
    redirect: indexgD7ow63TYOMeta?.redirect,
    component: () => import("/workspace/pages/tasks/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexIHAscP6Q13Meta?.name ?? "users-id",
    path: indexIHAscP6Q13Meta?.path ?? "/users/:id()",
    meta: indexIHAscP6Q13Meta || {},
    alias: indexIHAscP6Q13Meta?.alias || [],
    redirect: indexIHAscP6Q13Meta?.redirect,
    component: () => import("/workspace/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFDpy1ms2QGMeta?.name ?? "users",
    path: indexFDpy1ms2QGMeta?.path ?? "/users",
    meta: indexFDpy1ms2QGMeta || {},
    alias: indexFDpy1ms2QGMeta?.alias || [],
    redirect: indexFDpy1ms2QGMeta?.redirect,
    component: () => import("/workspace/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexl3kHCARXZ4Meta?.name ?? "users-new",
    path: indexl3kHCARXZ4Meta?.path ?? "/users/new",
    meta: indexl3kHCARXZ4Meta || {},
    alias: indexl3kHCARXZ4Meta?.alias || [],
    redirect: indexl3kHCARXZ4Meta?.redirect,
    component: () => import("/workspace/pages/users/new/index.vue").then(m => m.default || m)
  }
]